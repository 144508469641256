<template>
    <navigation-bar />
    <main id="main">
      <router-view />
    </main><!-- End #main -->
    <footer-section />
    <!-- <div id="preloader"></div> -->
    <router-link to="/#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></router-link>
</template>

<script>
import NavigationBar from './components/partials/NavigationBar.vue'
import FooterSection from './components/partials/FooterSection.vue'
export default {
  name: 'App',
  components: {
    NavigationBar,
    FooterSection
  }, 
  mounted() {
    
  // require('./assets/vendor/purecounter/purecounter_vanilla.js')
  // require('./assets/vendor/aos/aos.js')
  // require('./assets/vendor/bootstrap/js/bootstrap.bundle.min.js')
  // require('./assets/vendor/swiper/swiper-bundle.min.js')
  // require('./assets/vendor/php-email-form/validate.js')
  // require('./assets/js/main.js')
    var today=new Date();
		var annee = today.getFullYear();
		var sannee = document.getElementById('s_annee');
		sannee.innerHTML = annee;
  }
}
</script>

<style>

</style>
