<template>
    <div></div>
</template>

<script>
    export default {
        name: 'LogoutButton',
        created() {
            this.$store.dispatch('logout').then(() => {
              this.$router.push({
                name: 'login'
              })
            })
        }
    }
</script>

<style>

</style>