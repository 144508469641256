<template>
    <!-- ======= Footer ======= -->
    <footer id="footer">
        <div class="footer-top">
            <div class="container">
                <router-link to="/" title="Safety Management Group" class="logo me-auto"><img v-bind:src="require('../../assets/img/Logo SMG.png')" alt="" class="img-fluid"></router-link>
                <div class="row">
                    <div class="col-lg-3 col-md-6 footer-links">
                        <!-- <h4>Useful Links</h4> -->
                        <ul>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Qui sommes-nous ?</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Le blog</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Parrainage</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Services aux entreprise</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <!-- <h4>Our Services</h4> -->
                        <ul>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Webinaires à la demande</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Webinaires en direct</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Webinaires payants</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Cours en ligne</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Vidéos gratuites</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Evènement virtuel</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <!-- <h4>Our Services</h4> -->
                        <ul>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Aide en Ligne</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Centre d’assistance</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Nous contacter</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">Mentions légales</a></li>
                        <li><i class="bx bx-chevron-right"></i> <a href="#">CGU</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-newsletter">
                        <h4>Restez connectés</h4>
                        <div class="social-links text-center text-md-right pt-3 pt-md-0">
                        <!-- <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> -->
                        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                        &ensp;
                        <!-- <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a> -->
                        <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a> -->
                        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                        &ensp;
                        <a href="#" class="google-plus"><i class="bx bxl-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-md-flex py-4">
            <div class="me-md-auto text-center text-md-start">
                <div class="copyright">
                Copyright &copy;<strong><span></span></strong> - SMG, <span id="s_annee"></span>
                </div>
                <div class="credits">
                </div>
            </div>
        </div>
    </footer><!-- End Footer -->
</template>

<script>
export default {
    name: 'FooterSection',
}
</script>

<style>

</style>