<template>

    <!-- ======= Breadcrumbs ======= -->
    <!-- <div class="breadcrumbs" data-aos="fade-in"> -->
    <div class="breadcrumbs">
      <div class="container">
        <h2>Créez votre compte</h2>
      </div>
    </div><!-- End Breadcrumbs -->

     <!-- ======= Register Section ======= -->
     <section id="register" class="register section-bg">
      <div class="container">

        <div class="row">
          <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <div class="section-title-3">
              <h2>Plate-forme <br> de conférence vidéo <br> et cours en ligne <br> simple d'utilisation</h2>
            </div>
            <img v-bind:src="require('../assets/img/business-student-having-online-videocall-meeting-conference.jpg')" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <form v-on:submit.prevent="register" class="php-email-form">
              <div class="form-group mt-3">
                <input type="text" class="form-control" v-model="credential.name" id="name" placeholder="Votre nom">
              </div>

              <div class="form-group mt-3">
                <input type="email" class="form-control" v-model="credential.email" id="email" placeholder="E-mail">
              </div>

              <div class="form-group mt-3">
                <input type="password" class="form-control" v-model="credential.password" id="password" placeholder="Mot de passe">
              </div>

              <div class="form-group mt-3">
                <input type="password" class="form-control" v-model="credential.password_confirmation" id="pseudo" placeholder="Confirmer le mot de passe">
              </div>

              <!-- <div class="form-group mt-3">
                <input type="text" class="form-control" name="pseudo" id="pseudo" placeholder="Pseudo" required>
              </div>

              <div class="form-group my-3">
                <input type="text" class="form-control" name="phone" id="phone" placeholder="Numéro de téléphone (facultatif)">
              </div> -->
          
              <!-- <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div> -->
              <div class="text-center mt-3"><button type="submit">Inscrivez vous gratuitement</button></div>
            </form>
          </div>
        </div>

      </div>
    </section><!-- End Register Section -->
</template>

<script>
export default {
    name: 'RegisterPage',
    data() {
      return {
          credential: {
              name: null,
              email: null,
              password: null,
              password_confirmation: null
          }
      }
    },
    methods: {
        register() {
          this.$store.dispatch('register', this.credential).then(() => {
              this.$router.push({
                name: 'dashboard'
              })
          })
        }
    }
}
</script>

<style>

</style>