<template>
    <header-section />
    <services-section />
    <popular-courses-section />
    <div class="container">
      <hr>
    </div>
    <webinar-courses-section />
    <webinar-daily-course-section />
    <partners-section />
    <module-courses-section />
</template>

<script>
import HeaderSection from './HeaderSection.vue'
import ServicesSection from './ServicesSection.vue'
import PopularCoursesSection from './PopularCoursesSection.vue'
import WebinarCoursesSection from './WebinarCoursesSection.vue'
import WebinarDailyCourseSection from './WebinarDailyCourseSection.vue'
import PartnersSection from './PartnersSection.vue'
import ModuleCoursesSection from './ModuleCoursesSection.vue'
export default {
    name: 'HomePage',
    components: {
        HeaderSection,
        ServicesSection,
        PopularCoursesSection,
        WebinarCoursesSection,
        WebinarDailyCourseSection,
        PartnersSection,
        ModuleCoursesSection
    }
}
</script>

<style>

</style>