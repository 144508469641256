<template>
    <div v-if="error">
      <!-- ======= Breadcrumbs ======= -->
      <div class="breadcrumbs" data-aos="fade-in">
        <div class="container">
          <h2>Page introuvable</h2>
        </div>
      </div><!-- End Breadcrumbs -->

      <!-- ======= About Section ======= -->
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          
          <div class="row">
            <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
              <img v-bind:src="require('../../assets/img/about.jpg')" class="img-fluid" alt="">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <h2>Page introuvable</h2>
              <p>
                La page que vous recherchez est introuvable. Elle pourrait avoir été supprimée, renommée ou pourrait n'avoir jamais existé.
              </p>

            </div>
          </div>

        </div>
      </section><!-- End About Section -->
    </div>
    <div v-else>
      <!-- ======= Breadcrumbs ======= -->
      <div class="breadcrumbs" data-aos="fade-in">
        <div class="container">
          <h2>Détail de la formation</h2>
        </div>
      </div><!-- End Breadcrumbs -->
      <!-- ======= Cource Details Section ======= -->
      <section id="course-details" class="course-details">
        <div class="container" data-aos="fade-up">

          <div class="row">
            <div class="col-lg-9">
              <!-- <iframe width="980" height="620" v-bind:src="getCourse.videos[currentKey].video_url" frameborder="0" scrolling="0" allowfullscreen></iframe> -->
              <img v-bind:src="require('../../assets/img/course-details.jpg')" class="img-fluid" alt="">
              <h3>{{  getCourse.title }}</h3>
              <p>{{ getCourse.description }}</p>
            </div>
            <div class="col-lg-3">

              <div class="course-info d-flex justify-content-between align-items-center">
                <h5>Formateur</h5>
                <p><a href="#">{{ getCourse.user.name }}</a></p>
              </div>

              <div class="course-info d-flex justify-content-between align-items-center">
                <h5>Durée de la formation</h5>
                <p>6h 25mins</p>
              </div>

              <div class="course-info d-flex justify-content-between align-items-center">
                <h5>Coût de la formation</h5>
                <p>25000 fcfa</p>
              </div>

              <div class="text-center mt-3">
                <router-link to="">Accéder à la formation</router-link>
              </div>

              <!-- <div class="course-info d-flex justify-content-between align-items-center">
                <h5>Sièges disponibles</h5>
                <p>30</p>
              </div>

              <div class="course-info d-flex justify-content-between align-items-center">
                <h5>Horaire</h5>
                <p>5.00 pm - 7.00 pm</p>
              </div> -->

            </div>
          </div>

        </div>
      </section><!-- End Cource Details Section -->

      <!-- ======= Cource Details Tabs Section ======= -->
      <section id="cource-details-tabs" class="cource-details-tabs"> 
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-4">
              <ul v-for="(video, index) in getCourse.videos" v-bind:key="video.id" class="nav nav-tabs flex-column">
                <li class="nav-item">
                  <a v-on:click.prevent="setActive('tab-'+ (index + 1)); switchVideo(index)" class="nav-link" v-bind:class="{ active: isActived('tab-'+ (index + 1)) }" v-bind:href="'#tab-'+ (index + 1)">
                    Episode {{ index + 1 }} : {{ video.title }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-8 mt-4 mt-lg-0">
              <div class="tab-content">
                <div v-for="(video, index) in getCourse.videos" v-bind:key="video.id" class="tab-pane" v-bind:class="{ 'active show': isActived('tab-'+ (index + 1)) }" v-bind:id="'tab-'+ (index + 1)">
                  <div class="row">
                    <div class="col-lg-12 details order-2 order-lg-1">
                      <h3>Description de l'épisode {{ index + 1 }}</h3>
                      <p class="fst-italic"></p>
                      <p>{{ video.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section><!-- End Cource Details Tabs Section -->
  </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'CourseDetail',
        data() {
          return {
            id: this.$route.params.id,
            error: false,
            // isActive: false,
            activeItem: 'tab-1',
            currentKey: 0
          }
        },
        methods: {
          isActived(menuItem) {
            return this.activeItem === menuItem 
          },
          switchVideo(index) {
            this.currentKey = index
          },
          setActive(menuItem) {
            this.activeItem = menuItem
          },
          showCourse() {
            this.$store.dispatch('showCourse', this.id)
          }
        },
        computed: {
            ...mapGetters(['getCourse'])
        },
        created() {
          this.showCourse()
        }
    }
</script>

<style>

</style>