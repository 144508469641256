<template>
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs">
      <div class="container">
        <h2>Editer une formation</h2>
      </div>
    </div><!-- End Breadcrumbs -->
</template>

<script>
export default {
    name: 'CourseEdit',
}
</script>

<style>

</style>