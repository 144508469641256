<template>
    <!-- ======= Courses Section ======= -->
    <section id="courses" class="courses section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Nos parcours</h2>
        </div>

        <div class="row boxes">

          <div class="col-lg-3 mt-4 text-center box">
            <p>Manager QHSSE</p>
          </div>

          <div class="col-lg-3 mt-4 text-center box">
            <p>Superviseur QHSSE</p>
          </div>

          <div class="col-lg-3 mt-4 text-center box">
            <p>Gestion de projet</p>
          </div>

          <div class="col-lg-3 mt-4 text-center box">
            <p>Management</p>
          </div>

        </div>

      </div>
    </section><!-- End Partners Section -->
</template>

<script>
export default {
    name: 'ModuleCoursesSection', 
}
</script>

<style>

</style>