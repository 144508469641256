<template>
    <div class="col-lg-3">
        <ul class="nav nav-tabs flex-column">
            <li class="nav-item text-center">
                <router-link class="nav-link" to="/mon-compte">Acceuil</router-link>
            </li>
            <li class="nav-item text-center">
                <router-link class="nav-link" to="/mes-formations">Mes formations</router-link>
            </li>
            <li class="nav-item text-center">
                <router-link class="nav-link" to="/mes-favoris">Mes favoris</router-link>
            </li>
            <li class="nav-item text-center">
                <router-link class="nav-link" to="/mon-profil">Mon profil</router-link>
            </li>
            <li class="nav-item text-center">
                <router-link class="nav-link" to="/mon-panier">Mon panier</router-link>
            </li>
            <li class="nav-item text-center">
                <router-link class="nav-link" to="/se-deconnecter">Se deconnecter</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'DashboardMenu'
    }
</script>

<style>

</style>