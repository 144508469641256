<template>
    <div></div>
</template>

<script>
    export default {
        name: 'ShoppingCart'
    }
</script>

<style>

</style>