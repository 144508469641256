import axios from 'axios'
import { API_BASE_URL } from '../../config'


const state = {
    token: localStorage.getItem('accessToken') || null,
    user: {
        id: localStorage.getItem('userId') || null,
        name: localStorage.getItem('userName') || null,
        email: localStorage.getItem('userEmail') || null
    }
}

const getters = { 
    loggedIn: state => state.token !== null,
    getUser: state => state.user
}

const actions = {
    register({ commit }, credential) {
        return new Promise((resolve, reject) => {
            axios
                .post(API_BASE_URL+'/s-inscrire', {
                    name: credential.name,
                    email: credential.email,
                    password: credential.password,
                    password_confirmation: credential.password_confirmation
                })
                .then((response) => {
                    localStorage.setItem('accessToken', response.data.access_token)
                    localStorage.setItem('userId', response.data.user.id)
                    localStorage.setItem('userName', response.data.user.name)
                    localStorage.setItem('userEmail', response.data.user.email)
                    commit('setToken', response.data.access_token)
                    commit('setUserId', response.data.user.id)
                    commit('setUserName', response.data.user.name)
                    commit('setUserEmail', response.data.user.email)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    login({ commit }, credential) {
        return new Promise((resolve, reject) => {
            axios
                .post(API_BASE_URL + '/se-connecter', {
                    email: credential.email,
                    password: credential.password
                })
                .then(response => {
                    localStorage.setItem('accessToken', response.data.access_token)
                    localStorage.setItem('userId', response.data.user.id)
                    localStorage.setItem('userName', response.data.user.name)
                    localStorage.setItem('userEmail', response.data.user.email)
                    commit('setToken', response.data.access_token)
                    commit('setUserId', response.data.user.id)
                    commit('setUserName', response.data.user.name)
                    commit('setUserEmail', response.data.user.email)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    logout(context) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${context.state.token}`

        return new Promise((resolve, reject) => {
            axios
                .post(API_BASE_URL + '/se-deconnecter')
                .then(response => {
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('userId')
                    localStorage.removeItem('userName')
                    localStorage.removeItem('userEmail')
                    context.commit('removeToken')
                    context.commit('removeUserId')
                    context.commit('removeUserName')
                    context.commit('removeUserEmail')
                    resolve(response)
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    }
}

const mutations = {
    setToken(state, token) {
        state.token = token
    },
    setUserId(state, id) {
        state.user.id = id
    },
    setUserName(state, name) {
        state.user.name = name
    },
    setUserEmail(state, email) {
        state.user.email = email
    },
    removeToken(state) {
        state.token = null
    },
    removeUserId(state) {
        state.user.id = null
    },
    removeUserName(state) {
        state.user.name = null
    },
    removeUserEmail(state) {
        state.user.email = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}