<template>
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs" data-aos="fade-in">
      <div class="container">
        <h2>Mes formations</h2>
      </div>
    </div><!-- End Breadcrumbs -->

    <div class="container">
      <div class="row">
        <div class="col-lg-3 mt-lg-4 my-4 text-center">
          <img :src="require('../../assets/img/testimonials/testimonials-1.jpg')" class="rounded-circle w-50" alt="">
        </div>
        <div class="col-lg-9 mt-4 mt-lg-4">
          <h3>Bonjour <br>&ensp;{{ getUser.name }}</h3>
        </div>
        
      </div>
    </div>
      
    <!-- ======= Cource Details Tabs Section ======= -->
    <section id="cource-details-tabs" class="cource-details-tabs dashboard-menu">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <dashboard-menu />
          <div class="col-lg-9 mt-4 mt-lg-0">
            <div class="tab-content">
              <div class="tab-pane active show" id="tab-1">
                  <div class="col-lg-12 details order-2 order-lg-1">
                    
                    <!-- ======= Courses Section ======= -->
                    <section id="courses" class="courses">
                      <div class="container" data-aos="fade-up">
                        
                        <div class="row" data-aos="zoom-in" data-aos-delay="100">
                          <h3>Mes formations</h3>

                          <div class="col-lg-4 col-md-6 d-flex align-items-stretch my-4">
                            <div class="course-item">
                              <img v-bind:src="require('../../assets/img/course-1.jpg')" class="img-fluid" alt="...">
                              <div class="course-content">
                                <div class="d-flex justify-content-between align-items-center mb-3">
                                  <!-- <h4>Web Development</h4>
                                  <p class="price">25000 FCFA</p> -->
                                </div>

                                <h3><router-link to="">titre</router-link></h3>
                                <p>description</p>
                                <div class="trainer d-flex justify-content-between align-items-center">
                                  <div class="trainer-profile d-flex align-items-center">
                                    <img v-bind:src="require('../../assets/img/trainers/trainer-1.jpg')" class="img-fluid" alt="">
                                    <span>nom formateur</span>
                                  </div>
                                  <div class="trainer-rank d-flex align-items-center">
                                    <i class="bx bx-user"></i>&nbsp;1
                                    &nbsp;&nbsp;
                                    <i class="bx bx-movie-play"></i>&nbsp;10
                                    <!-- <i class="bx bx-heart"></i>&nbsp;65 -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> <!-- End Course Item-->

                          <div class="col-lg-4 col-md-6 d-flex align-items-stretch my-4">
                            <div class="course-item">
                              <img v-bind:src="require('../../assets/img/course-1.jpg')" class="img-fluid" alt="...">
                              <div class="course-content">
                                <div class="d-flex justify-content-between align-items-center mb-3">
                                  <!-- <h4>Web Development</h4>
                                  <p class="price">25000 FCFA</p> -->
                                </div>

                                <h3><router-link to="">titre</router-link></h3>
                                <p>description</p>
                                <div class="trainer d-flex justify-content-between align-items-center">
                                  <div class="trainer-profile d-flex align-items-center">
                                    <img v-bind:src="require('../../assets/img/trainers/trainer-1.jpg')" class="img-fluid" alt="">
                                    <span>nom formateur</span>
                                  </div>
                                  <div class="trainer-rank d-flex align-items-center">
                                    <i class="bx bx-user"></i>&nbsp;1
                                    &nbsp;&nbsp;
                                    <i class="bx bx-movie-play"></i>&nbsp;10
                                    <!-- <i class="bx bx-heart"></i>&nbsp;65 -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> <!-- End Course Item-->
                          
                          <div class="col-lg-4 col-md-6 d-flex align-items-stretch my-4">
                            <div class="course-item">
                              <img v-bind:src="require('../../assets/img/course-1.jpg')" class="img-fluid" alt="...">
                              <div class="course-content">
                                <div class="d-flex justify-content-between align-items-center mb-3">
                                  <!-- <h4>Web Development</h4>
                                  <p class="price">25000 FCFA</p> -->
                                </div>

                                <h3><router-link to="">titre</router-link></h3>
                                <p>description</p>
                                <div class="trainer d-flex justify-content-between align-items-center">
                                  <div class="trainer-profile d-flex align-items-center">
                                    <img v-bind:src="require('../../assets/img/trainers/trainer-1.jpg')" class="img-fluid" alt="">
                                    <span>nom formateur</span>
                                  </div>
                                  <div class="trainer-rank d-flex align-items-center">
                                    <i class="bx bx-user"></i>&nbsp;1
                                    &nbsp;&nbsp;
                                    <i class="bx bx-movie-play"></i>&nbsp;10
                                    <!-- <i class="bx bx-heart"></i>&nbsp;65 -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> <!-- End Course Item-->

                        </div>

                      </div>
                    </section><!-- End Courses Section -->

                  </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Cource Details Tabs Section -->

</template>

<script>
    import { mapGetters } from 'vuex'
    import DashboardMenu from './DashboardMenu.vue'
    export default {
        name: 'MyCoursesPage',
        components: {
          DashboardMenu
        },
        computed: {
          ...mapGetters(['getUser'])
        }
    }
</script>

<style>

</style>