<template>
    <div></div>
    <!-- ======= Counts Section ======= -->
    <section id="counts" class="counts section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Nos services</h2>
        </div>

        <div class="row justify-content-center align-items-center counters">

          <div class="col-lg-2 col-6 text-center">
            <img class="img-fluid" v-bind:src="require('../../assets/img/iconmonstr-school-7.svg')" alt="">
            <p class="btn-services">Consulting <br> & formation</p>
          </div>

          <div class="col-lg-2 col-6 text-center">
            <img v-bind:src="require('../../assets/img/iconmonstr-barcode-7.svg')" alt="">
            <p class="btn-services">Digitalisation <br> des processus HSSE</p>
          </div>

          <div class="col-lg-2 col-6 text-center">
            <img v-bind:src="require('../../assets/img/iconmonstr-marketing-4.svg')" alt="">
            <p class="btn-services">Service de <br> management QHSSE</p>
          </div>

          <div class="col-lg-2 col-6 text-center">
            <img v-bind:src="require('../../assets/img/iconmonstr-customer-9.svg')" alt="">
            <p class="btn-services">Mise à disposition <br> de personnel</p>
          </div>

          <div class="col-lg-2 col-6 text-center">
            <img v-bind:src="require('../../assets/img/iconmonstr-shield-27.svg')" alt="">
            <p class="btn-services">Sécurité  <br> & études</p>
          </div>

        </div>

      </div>
    </section>
    <!-- End Counts Section -->
</template>

<script>
export default {
    name: 'ServicesSection',
}
</script>

<style>

</style>