// require('./assets/vendor/animate.css/animate.min.css') 
// require('./assets/vendor/aos/aos.css')
require('./assets/vendor/bootstrap/css/bootstrap.min.css')
// require('./assets/vendor/bootstrap-icons/bootstrap-icons.css')
require('./assets/vendor/boxicons/css/boxicons.min.css')
// require('./assets/vendor/remixicon/remixicon.css')
// require('./assets/vendor/swiper/swiper-bundle.min.css')
require('./assets/css/style.css')

import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'

createApp(App)
.use(store)
.use(router)
.mount('#app')