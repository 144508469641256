<template>
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs" data-aos="fade-in">
      <div class="container">
        <h2>Tableau de bord</h2>
      </div>
    </div><!-- End Breadcrumbs -->

    <div class="container">
      <div class="row">
        <div class="col-lg-3 mt-lg-4 my-4 text-center">
          <img :src="require('../../assets/img/testimonials/testimonials-1.jpg')" class="rounded-circle w-50" alt="">
        </div>
        <div class="col-lg-9 mt-4 mt-lg-4">
          <h3>Bonjour <br>&ensp;{{ getUser.name }}</h3>
        </div>
        
      </div>
    </div>
      
    <!-- ======= Cource Details Tabs Section ======= -->
    <section id="cource-details-tabs" class="cource-details-tabs dashboard-menu">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <dashboard-menu />
          <div class="col-lg-9 mt-4 mt-lg-0">
            <div class="tab-content">
              <div class="tab-pane active show" id="tab-1">
                <div class="row">
                  <div class="col-lg-12 details order-2 order-lg-1">
                    <h3>Acceuil</h3>
                    <p class="fst-italic">Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka</p>
                    <p>Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni. Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Cource Details Tabs Section -->

</template>

<script>
    import { mapGetters } from 'vuex'
    import DashboardMenu from './DashboardMenu.vue'
    export default {
        name: 'DashboardPage',
        data() {
          return {
            active: false
          }
        },
        components: {
          DashboardMenu
        },
        computed: {
          ...mapGetters(['getUser'])
        }
    }
</script>

<style>

</style>