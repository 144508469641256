import axios from 'axios'
import { API_BASE_URL } from '../../config'


const state = {
    courses: [],
    course: {
        title: null,
        description: null,
        user: {
            name: null,
            email: null
        },
        videos: []
    },
}

const getters = {
    getCourses: state => state.courses,
    getCourse: state => state.course
    
}

const actions = {
    allCourses({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_BASE_URL + '/formations')
                .then(response => {
                    commit('setCourses', response.data.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    showCourse({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(API_BASE_URL + '/formations/' + id)
                .then(response => {
                    commit('setCourse', response.data[0])
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    toggleProgress(videoId) {
            axios
                .post(API_BASE_URL + '/toggleProgress', {
                    videoId: videoId
                })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.error(error)
                })
    }
}

const mutations = {
    setCourses: (state, courses) => (state.courses = courses),
    setCourse: (state, course) => (state.course = course)
}

export default {
    state,
    getters,
    actions,
    mutations
}