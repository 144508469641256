import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/home/HomePage.vue'
import ServiceItem from '../components/ServiceItem.vue'
import CourseItem from '../components/courses/CourseItem.vue'
import CourseDetail from '../components/courses/CourseDetail.vue'
import CourseCreate from '../components/courses/CourseCreate.vue'
import CourseEdit from '../components/courses/CourseEdit.vue'
import ShoppingCart from '../components/ShoppingCart.vue'
import LoginPage from '../components/LoginPage.vue'
import RegisterPage from '../components/RegisterPage.vue'
import DashboardPäge from '../components/dashboard/DashboardPage.vue'
import MyCoursesPage from '../components/dashboard/MyCoursesPage.vue'
import WishListPage from '../components/dashboard/WishListPage.vue'
import ProfilePage from '../components/dashboard/ProfilePage.vue'
import ProfileEdit from '../components/dashboard/ProfileEdit.vue'
import MyCartListPage from '../components/dashboard/MyCartListPage.vue'
import LogoutButton from '../components/partials/LogoutButton.vue'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/services',
        name: 'services',
        component: ServiceItem
    },
    { 
        path: '/formations',
        name: 'courses.index',
        component: CourseItem
    },
    { 
        path: '/formations/:id',
        name: 'courses.show',
        component: CourseDetail
    },
    {
        path: '/formations/creer',
        name: '/courses.create',
        component: CourseCreate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/formations/:id/editer',
        name: '/courses.edit',
        component: CourseEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/panier',
        name: 'shopping-cart',
        component: ShoppingCart
    },
    {
        path: '/se-connecter',
        name: 'login',
        component: LoginPage,
        meta: {
            visitor: true
        }
    },
    {
        path: '/s-inscrire',
        name: 'register',
        component: RegisterPage,
        meta: {
            visitor: true
        }
    },
    {
        path: '/mon-compte',
        name: 'dashboard',
        component: DashboardPäge,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/mes-formations',
        name: 'my-courses',
        component: MyCoursesPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/mes-favoris',
        name: 'my-wishlist',
        component: WishListPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/mon-profil',
        name: 'my-profile',
        component: ProfilePage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/mon-profil/mettre-a-jour',
        name: 'my-profile.edit',
        component: ProfileEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/mon-panier',
        name: 'my-cart-list',
        component: MyCartListPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/se-deconnecter',
        name: 'logout',
        component: LogoutButton,
        meta: {
            requiresAuth: true
        }
    }
  ]

 const router = createRouter({
        history: createWebHistory(),
        routes,
        linkActiveClass: 'active'
  })

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.loggedIn) {
        next({
          name: 'login'
        })
      } else {
        next()
      }
    } else if (to.matched.some(record => record.meta.visitor)) {
        if (store.getters.loggedIn) {
            next({
                name: 'Dashboard'
            })
        } else {
            next()
        }
    } 
    else {
      next()
    }
})

export default router