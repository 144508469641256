<template>
    <!-- ======= Popular Courses Section ======= -->
    <section id="popular-courses" class="courses">
      <div class="container" data-aos="fade-up">

        <div class="section-title-2">
          <h2>Cours les plus populaires</h2>
          <!-- <p>Popular Courses</p> -->
        </div>

        <div class="row" data-aos="zoom-in" data-aos-delay="100">

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="course-item">
              <img v-bind:src="require('../../assets/img/course-1.jpg')" class="img-fluid" alt="...">
              <div class="course-content">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h4>Domaine d'activité</h4>
                  <p class="price">25000 FCFA</p>
                </div>

                <h3><router-link to="/formations/1">Conception de sites web</router-link></h3>
                <div class="trainer d-flex justify-content-between align-items-center">
                  <div class="trainer-profile d-flex align-items-center">
                    <img v-bind:src="require('../../assets/img/trainers/trainer-1.jpg')" class="img-fluid" alt="">
                    <span>Formateur</span>
                  </div>
                  <div class="trainer-rank d-flex align-items-center">
                    <i class="bx bx-user"></i>&nbsp;50
                    &nbsp;&nbsp;
                    <i class="bx bx-heart"></i>&nbsp;65
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- End Course Item-->

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div class="course-item">
              <img v-bind:src="require('../../assets/img/course-2.jpg')" class="img-fluid" alt="...">
              <div class="course-content">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h4>Domaine d'activité</h4>
                  <p class="price">25000 FCFA</p>
                </div>

                <h3><router-link to="/formations/2">Optimisation des moteurs de recherche</router-link></h3>
                <div class="trainer d-flex justify-content-between align-items-center">
                  <div class="trainer-profile d-flex align-items-center">
                    <img v-bind:src="require('../../assets/img/trainers/trainer-2.jpg')" class="img-fluid" alt="">
                    <span>Formateur</span>
                  </div>
                  <div class="trainer-rank d-flex align-items-center">
                    <i class="bx bx-user"></i>&nbsp;35
                    &nbsp;&nbsp;
                    <i class="bx bx-heart"></i>&nbsp;42
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- End Course Item-->

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div class="course-item">
              <img v-bind:src="require('../../assets/img/course-3.jpg')" class="img-fluid" alt="...">
              <div class="course-content">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h4>Domaine d'activité</h4>
                  <p class="price">25000 FCFA</p>
                </div>

                <h3><router-link to="/formations/3">Rédaction de textes</router-link></h3>
                <div class="trainer d-flex justify-content-between align-items-center">
                  <div class="trainer-profile d-flex align-items-center">
                    <img v-bind:src="require('../../assets/img/trainers/trainer-3.jpg')" class="img-fluid" alt="">
                    <span>Formateur</span>
                  </div>
                  <div class="trainer-rank d-flex align-items-center">
                    <i class="bx bx-user"></i>&nbsp;20
                    &nbsp;&nbsp;
                    <i class="bx bx-heart"></i>&nbsp;85
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- End Course Item-->

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div class="course-item">
              <img v-bind:src="require('../../assets/img/course-3.jpg')" class="img-fluid" alt="...">
              <div class="course-content">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <h4>Domaine d'activité</h4>
                  <p class="price">25000 FCFA</p>
                </div>

                <h3><router-link to="/formations/4">Graphisme</router-link></h3>
                <div class="trainer d-flex justify-content-between align-items-center">
                  <div class="trainer-profile d-flex align-items-center">
                    <img v-bind:src="require('../../assets/img/trainers/trainer-3.jpg')" class="img-fluid" alt="">
                    <span>Formateur</span>
                  </div>
                  <div class="trainer-rank d-flex align-items-center">
                    <i class="bx bx-user"></i>&nbsp;20
                    &nbsp;&nbsp;
                    <i class="bx bx-heart"></i>&nbsp;85
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- End Course Item-->

        </div>

      </div>
    </section><!-- End Popular Courses Section -->
</template>

<script>
export default {
    name: 'PopularCoursesSection',
}
</script>

<style>

</style>