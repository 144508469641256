<template>
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs" data-aos="fade-in">
        <div class="container">
          <h2>Nos services</h2>
        </div>
    </div><!-- End Breadcrumbs -->
</template>

<script>
export default {
    name: 'ServiceItem',
}
</script>

<style>

</style>