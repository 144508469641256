<template>
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs">
      <div class="container">
        <h2>Formations</h2>
      </div>
    </div><!-- End Breadcrumbs -->

    <!-- ======= Courses Section ======= -->
    <section id="courses" class="courses">
      <div class="container" data-aos="fade-up">

        <div class="row" data-aos="zoom-in" data-aos-delay="100">

          <div v-for="course in getCourses" v-bind:key="course.id" class="col-lg-4 col-md-6 d-flex align-items-stretch my-4">
            <div class="course-item">
              <img v-bind:src="require('../../assets/img/course-1.jpg')" class="img-fluid" alt="...">
              <div class="course-content">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <!-- <h4>Web Development</h4>
                  <p class="price">25000 FCFA</p> -->
                </div>

                <h3><router-link :to="{name: 'courses.show', params: {id: course.id}}">{{ course.title }}</router-link></h3>
                <p>{{ course.description }}</p>
                <div class="trainer d-flex justify-content-between align-items-center">
                  <div class="trainer-profile d-flex align-items-center">
                    <img v-bind:src="require('../../assets/img/trainers/trainer-1.jpg')" class="img-fluid" alt="">
                    <span>{{ course.user.name }}</span>
                  </div>
                  <div class="trainer-rank d-flex align-items-center">
                    <i class="bx bx-user"></i>&nbsp;{{ course.participants }}
                    &nbsp;&nbsp;
                    <i class="bx bx-movie-play"></i>&nbsp;{{ course.videos_count }}
                    <!-- <i class="bx bx-heart"></i>&nbsp;65 -->
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- End Course Item-->

        </div>

      </div>
    </section><!-- End Courses Section -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'CourseItem',
    methods: {
      ...mapActions(['allCourses'])
    },
    computed: {
      ...mapGetters(['getCourses'])
  },
  created() {
    this.allCourses()
  }
}
</script>

<style>

</style>