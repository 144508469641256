<template>
    <!-- ======= Partners Section ======= -->
    <section id="partners" class="partners section-bg">
      <div class="container">
        <div class="section-title">
          <h2>Ils nous font confiance</h2>
          <p>Plus de 1 249 598 utilisateurs et des milliers d'entreprises, développent leurs compétences en se formant au quotidien sur <router-link to="/">smg.com</router-link>. 
            Nos clients ont attribué une moyenne de 4.6/5 aux formations présentes sur smg.com, et rédigé 90 107 avis.</p>
        </div>
      </div>
    </section><!-- End Partners Section -->
</template>

<script>
export default {
    name: 'PartnersSection',
}
</script>

<style>

</style>