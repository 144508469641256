<template>
    <!-- ======= Breadcrumbs ======= -->
    <!-- <div class="breadcrumbs" data-aos="fade-in"> -->
    <div class="breadcrumbs">
      <div class="container">
        <h2>Connectez-vous à votre compte</h2>
      </div>
    </div><!-- End Breadcrumbs -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">

      <!-- <div class="container" data-aos="fade-up"> -->
      <div class="container">

        
        <div class="row mt-5">
          <div class="col-lg-3">
          </div>
          
          <div class="col-lg-6 mt-5 mt-lg-0">
            <!-- <a href="" class="gmail"><i class="bx bxl-google"></i> Se connecter avec Gmail</a>
            <hr class="mb-2"> -->
            <form v-on:submit.prevent="login" role="form" class="php-email-form">
              <div class="form-group mt-3">
                <input type="text" class="form-control" v-model="credential.email" id="subject" placeholder="Email">
              </div>
              <div class="form-group mt-3">
                <input type="password" class="form-control" v-model="credential.password" id="password" placeholder="Mot de passe">
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Se connecter</button></div>
              <div class="my-3">
                <p class="text-center mt-3">Vous n'avez pas de compte ? <router-link to="/s-inscrire">Inscrivez-vous</router-link></p>
              </div>

            </form>
          </div>

          <div class="col-lg-3">
          </div>
        </div>

      </div>
    </section><!-- End Contact Section -->
</template>

<script>
export default {
    name: 'LoginPage',
    data() {
        return {
            credential: {
                email: null, 
                password: null
            }
        }
    },
    methods: {
        login() {
          this.$store.dispatch('login', this.credential).then(() => {
              this.$router.push({
                name: 'dashboard'
              })
          })
        }
    }
}
</script>

<style>

</style>