<template>
    <strong><editor api-key="{{ config.API_KEY }}" init="{plugins: 'wordcount'}" initial-value=""></editor></strong>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    name: 'TextareaBox',
    components: {
        Editor
    },
    data() {
        return {
        }
    }
}
</script>

<style>

</style>
