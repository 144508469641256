<template>
    <!-- ======= Webinar Section ======= -->
    <section id="webinar" class="webinar section-bg">

        <div class="section-title">
        <h2>Webinaire du jour</h2>
        </div>

        <div id="webinar-bg">
        <div class="container position-relative">
            <div class="row">
            <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                <h3 class="online">En ligne <img v-bind:src="require('../../assets/img/iconmonstr-video-16-R.svg')" alt="" width="21" height="21"></h3>
                <p class="section-title-4">Formez vous <br> à la digitalisation <br> des processus HSSE</p>
                <p class="section-content">
                Toutes les démarches vous sont bien établi par nos experts
                </p>
                <router-link to="/#" class="btn-get-webinar">Démarrer le webinaire</router-link>
            </div>
            </div>
        </div>

        </div>
    </section><!-- End Webinar Section -->
</template>

<script>
export default {
    name: 'WebinarDailyCourseSection',
}
</script>

<style>

</style>