<template>
    <!-- ======= Breadcrumbs ======= -->
    <div class="breadcrumbs">
      <div class="container">
        <h2>Créer une nouvelle formation</h2>
      </div>
    </div><!-- End Breadcrumbs -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <!-- <div class="container" data-aos="fade-up"> -->
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <form v-on:submit.prevent="createCourse" class="php-email-form">
              <div class="form-group mt-3">
                <input type="text" class="form-control" v-model="form.title" id="name" placeholder="Le titre de la formation">
              </div>

              <div class="form-group mt-3">
                <input type="text" class="form-control" v-model="form.course_url" id="" placeholder="Lien de la formation">
              </div>

              <div class="form-group mt-3">
                <TextareaBox />
              </div>

              <!-- <div class="form-group mt-3">
                <input type="text" class="form-control" name="pseudo" id="pseudo" placeholder="Pseudo" required>
              </div>

              <div class="form-group my-3">
                <input type="text" class="form-control" name="phone" id="phone" placeholder="Numéro de téléphone (facultatif)">
              </div> -->
          
              <!-- <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div> -->
              <div class="text-center mt-3"><button type="submit">Créer la formation</button></div>
            </form>
          </div>

        </div>
      </div>
    </section><!-- End Contact Section -->
</template>

<script>
import TextareaBox from '../partials/TextareaBox.vue'
export default {
    name: 'CourseCreate',
    components: {
      TextareaBox
    },
    data() {
        return {
            form: {
                title: null, 
                course_url: null
            }
        }
    }
}
</script>

<style>

</style>