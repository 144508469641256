<template>
    <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex justify-content-center align-items-center">
    <div class="container position-relative" data-aos="zoom-in" data-aos-delay="100">
      <!-- <h1>Learning Today,<br>Leading Tomorrow</h1> -->
      <h2>Développez vos compétences numériques <br>
        avec nos cours 100% à distance <br>
        Grâce à nos formations en ligne, sécurisez votre avenir <br>
        en misant sur vos compétences numériques. Formez-vous <br> à votre rythme, aux côtés d’experts et d'artistes 
        passionnés.</h2>
      <router-link to="#" class="btn-get-courses">Tous nos cours</router-link>
      <router-link to="#" class="btn-get-started">
        <img class="mr-2" v-bind:src="require('../../assets/img/iconmonstr-video-16.svg')" alt="" width="21" height="21">
        Webinaire
      </router-link>
    </div>
  </section><!-- End Hero -->
</template>

<script>
export default {
    name: 'HeaderSection',
}
</script>

<style>

</style>