<template>
    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top">
      <div class="container d-flex align-items-center">

        <router-link to="/" title="Safety Management Group" class="logo me-auto"><img v-bind:src="require('../../assets/img/Logo SMG.png')" alt="" class="img-fluid"></router-link>

        <nav id="navbar" class="navbar order-last order-lg-0">
          <ul>
            <li><router-link to="/services">Services</router-link></li>
            <li><router-link to="/formations">Formations</router-link></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->

        <router-link to="/se-connecter" v-if="!loggedIn" class="get-started-btn">Se connecter</router-link>
        <router-link to="/mon-compte" v-if="loggedIn" class="px-4">Mon compte</router-link>
        <router-link to="/mon-panier" class="px-4">Mon panier</router-link>
      </div>
    </header><!-- End Header -->
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'NavigationBar',
    methods: {

    },
    computed: {
      ...mapGetters(['loggedIn'])
    }
}
</script>

<style>

</style>